import React, {useEffect, useState} from 'react';
import axios from "axios";

export const VideoContext = React.createContext({
    url: null,
    onChangeVideo: (value) => {},
    onHide: () => {}
})

export const VideoContextProvider = props => {
    const [videoData, setVideoData] = useState(null);
    const [url, setUrl] = useState(null);
    const baseURL = 'https://accessibility.mk/slv/'

    useEffect(() => {
        const items = localStorage.getItem("videos")
        // if (localStorage.getItem("videos")) {
        //     const videos = JSON.parse(items);
        //     if (videos) {
        //         setVideoData(videos);
        //     }
        // } else {
        //     axios.get('/locales/sign-language/sign-language.json')
        //         .then(response => {
        //             setVideoData(response.data)
        //             localStorage.setItem("videos", JSON.stringify(response.data))
        //         })
        //         .catch(() => setVideoData(null));
        // }

        axios.get('/locales/sign-language/sign-language.json')
            .then(response => {
                setVideoData(response.data)
                localStorage.setItem("videos", JSON.stringify(response.data))
            })
            .catch(() => setVideoData(null));
    },[])

    const onChange = (value) => {
        if (videoData) {
            const data = videoData[value]
            const url = "https://accessibility.mk/slv/" + data;
            if (data) {
                setUrl(url);
            }
        }
    }

    const onHide = () => {
        if (videoData) {
            setUrl(null);
        }
    }

    return (
        <VideoContext.Provider value={{
            url: url,
            onChangeVideo: onChange,
            onHide:onHide
        }}>
            {props.children}
        </VideoContext.Provider>
    )
}