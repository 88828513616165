import {useTranslation} from "react-i18next";
import {Heading} from "../UI/Heading";
import React, {useState} from "react";
import PhoneInput from 'react-phone-input-2'
import axios from "axios";
import {Label} from "../UI/Label";
import {ReCaptcha} from "../UI/ReCaptcha";
import {MouseHover} from "../UI/MouseHover";
import {toast} from "react-toastify";

export const Contact = (props) => {
    const [contact, setContact] = useState({
        name: {
            value: "",
            hasError: false,
            errorMessage: "Полето е задолжително"
        },
        lastName: {
            value: "",
            hasError: false,
            errorMessage: "Полето е задолжително"
        },
        phoneNumber: {
            value: "+389",
            hasError: false,
            errorMessage: "Полето е задолжително"
        },
        email: {
            value: "",
            hasError: false,
            errorMessage: "Полето е задолжително"
        },
        message: {
            value: "",
        },
        reCaptcha: {
            value: null,
            hasError: false,
            errorMessage: "Полето е задолжително"
        },
    });
    const {t} = useTranslation();

    const handleChange = (key, value) => {
        setContact(prevState => {
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    value: value
                }
            }
        })
    }

    const onReCaptchaChange = (value) => {
        setContact(prevState => {
            return {
                ...prevState,
                reCaptcha: {
                    ...prevState.reCaptcha,
                    value: value
                }
            }
        })
    }

    const isValid = () => {
        let isValid = true;
        const newContact = {...contact}
        if (!contact.name.value) {
            newContact.name.hasError = true;
            isValid = false;
        }
        if (!contact.lastName.value) {
            newContact.lastName.hasError = true;
            isValid = false;
        }
        if (
            !contact.phoneNumber.value ||
            (contact.phoneNumber.value.trim() === "+389" ||
                contact.phoneNumber.value.trim() === "+381" ||
                contact.phoneNumber.value.trim() === "+385")
        ) {
            newContact.phoneNumber.hasError = true;
            isValid = false;
        }
        if (!contact.email.value) {
            newContact.email.hasError = true;
            isValid = false;
        }
        if (!contact.reCaptcha.value) {
            newContact.reCaptcha.hasError = true;
            isValid = false;
        }
        if (!isValid) {
            setContact(newContact);
        }

        return isValid;
    }

    const handleSubmit = () => {
        if (isValid()) {
            const mailFormData = new FormData();

            mailFormData.append("email", contact.email.value);
            mailFormData.append("subject", "Апликација за Accessibility");
            mailFormData.append("message",
                `${contact.message.value}\n\nКонтакт:\nИме и Презиме: ${contact.name.value} ${contact.lastName.value}\nТелефонскиот број: ${contact.phoneNumber.value}\nЕмаил адресата: ${contact.email.value}`
            );

            axios.post(`https://ireason.mk/send_mail_simple.php`, mailFormData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(response => {
                    toast.success(t("Успешно беше испратено барањето за контант!"))
                })
                .catch(error => {
                    toast.error(t("Настана грешка при барање за контант!"))
                })
                .finally(() => {
                    setContact({
                        name: {
                            value: "",
                            hasError: false,
                            errorMessage: "Полето е задолжително"
                        },
                        lastName: {
                            value: "",
                            hasError: false,
                            errorMessage: "Полето е задолжително"
                        },
                        phoneNumber: {
                            value: "+389",
                            hasError: false,
                            errorMessage: "Полето е задолжително"
                        },
                        email: {
                            value: "",
                            hasError: false,
                            errorMessage: "Полето е задолжително"
                        },
                        message: {
                            value: "",
                        },
                        reCaptcha: {
                            value: null,
                            hasError: false,
                            errorMessage: "Полето е задолжително"
                        },
                    })
                })
        }
    }

    return (
        <section id={"contact"} className={"container my-5"}>
            <div className={"row mx-auto my-5 py-5 text-start"}>
                <div className={"col-lg-8 col-md-8 col-sm-12 mx-auto bg-white rounded border shadow p-4"}>
                    <Heading type={1} className={"text-center pb-4 border-bottom"}>contact-us</Heading>
                    <form className="row g-3">
                        <div className="col-sm-12 col-md-6">
                            <Label htmlFor="name" className="form-label">{t("name")}</Label>
                            <input id={"name"}
                                   type={"text"}
                                   value={contact.name.value}
                                   onChange={(event) => handleChange("name", event.target.value)}
                                   className={`form-control ${contact.name.hasError ? "border-danger" : ""}`}
                                   placeholder={t("name")}/>
                            {/*{contact.name.hasError ? <span className={"text-danger"}>*{contact.name.errorMessage}</span>:null}*/}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <Label htmlFor="lastName" className="form-label">{t("lastname")}</Label>
                            <input id={"lastName"}
                                   type={"text"}
                                   value={contact.lastName.value}
                                   onChange={(event) => handleChange("lastName", event.target.value)}
                                   className={`form-control ${contact.lastName.hasError ? "border-danger" : ""}`}
                                   placeholder={t("lastname")}/>
                            {/*{contact.lastName.hasError ? <span className={"text-danger"}>*{contact.lastName.errorMessage}</span>:null}*/}
                        </div>

                        <div className="col-12">
                            <Label htmlFor="email" className="form-label">{t("email")}</Label>
                            <input id={"email"}
                                   type={"email"}
                                   value={contact.email.value}
                                   onChange={(event) => handleChange("email", event.target.value)}
                                   className={`form-control ${contact.email.hasError ? "border-danger" : ""}`}
                                   placeholder={t("email")}/>
                            {/*{contact.email.hasError ? <span className={"text-danger"}>*{contact.email.errorMessage}</span>:null}*/}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <Label htmlFor="phoneNumber" className="form-label">{t("phone-number")}</Label>
                            <PhoneInput
                                defaultCountry='mk'
                                value={contact.phoneNumber.value}
                                fullWidth
                                inputClass={`py-2 w-100 ${contact.phoneNumber.hasError ? "border-danger" : ""}`}
                                onChange={(value) => handleChange("phoneNumber", value)}
                                onlyCountries={['mk', 'rs', 'hr']}
                                required={"true"}
                                masks={{'mk': '.. ... ...', 'rs': '... .... ...', 'hr': '.. ... ...'}}
                            />
                            {/*{contact.phoneNumber.hasError ? <span className={"text-danger"}>*{contact.phoneNumber.errorMessage}</span>:null}*/}
                        </div>
                        <div className="col-12">
                            <Label htmlFor="message" className="form-label">{t("message")}</Label>
                            <textarea value={contact.message.value}
                                      className={"form-control"}
                                      placeholder={t("message")}
                                      onChange={(event) => handleChange("message", event.target.value)}/>
                            {/*{contact.email.hasError ? <span className={"text-danger"}>*{contact.email.errorMessage}</span>:null}*/}
                        </div>
                        <div id={"reCaptcha"} className={`col-12 ${contact.reCaptcha.hasError ? "border-danger" : ""}`}>
                            <ReCaptcha onChange={onReCaptchaChange}/>
                            {/*{contact.reCaptcha.hasError ? <span className={"text-danger"}>*{contact.reCaptcha.errorMessage}</span>:null}*/}
                        </div>
                        <div className="col-12">
                            <button type="button" className="btn btn-primary " onClick={handleSubmit}>
                                <MouseHover>send</MouseHover>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}