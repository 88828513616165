import {Heading} from "./Heading";
import React from "react";
import {Modal} from "@material-ui/core";
import {Button} from "./Button";


export const ModalUI = ({isOpen, title, body, onClose}) => {
    return (
        <Modal open={isOpen}
               onClose={onClose}
               className={"modal-size"} >
            <div className={"card mx-3"}>
                {
                    title ?
                        <div className={"card-header bg-white border-bottom-0 text-center"}>
                            <Heading type={1} className={"float-start  border-bottom pb-2 w-100"}>{title}</Heading>
                        </div> :
                        <div className={"card-header bg-white border-bottom-0"}>
                            <button className={"btn btn-close modal-close-button"} onClick={onClose}/>
                        </div>
                }

                <div className={"card-body py-0 bg-white"}>
                    {body}
                </div>
                {
                    title ?
                        <div className={"card-footer bg-white border-top-0 text-center"}>
                            <Button className={"btn btn-danger"} onClick={onClose}>close</Button>
                        </div> : null
                }
            </div>
        </Modal>
    )
}