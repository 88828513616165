import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import classes from "./options.module.css";
import {useTranslation} from "react-i18next";
import {MouseHover} from "./MouseHover";

export const LinkTypes = {
    "HASH": "HASH",
    "LINK": "LINK",
    "REDIRECT": "REDIRECT"
}

export const Link = ({children, className, url, type}) => {
    const optionsContext = useContext(OptionContext);
    const linkStyle = [className];

    if (optionsContext.fontSize === 1) {
        linkStyle.push(className && className.includes("small") ? classes.SmallParagraph1 : classes.Paragraph1)
    } else if (optionsContext.fontSize === 2) {
        linkStyle.push(className && className.includes("small") ? classes.SmallParagraph2 : classes.Paragraph2)
    } else if (optionsContext.fontSize === 3) {
        linkStyle.push(className && className.includes("small") ? classes.SmallParagraph3 : classes.Paragraph3)
    } else {
        linkStyle.push(className && className.includes("small") ? classes.SmallParagraph4 : classes.Paragraph4)
    }
    if (optionsContext.linkMark) {
        linkStyle.push(classes.Link)
    }
    else if (optionsContext.highlightText && !optionsContext.linkMark) {
        linkStyle.push(classes.Highlight)
    }

    return (
        <>
            {type === LinkTypes.HASH ?  <a href={url} className={"text-decoration-none"}><MouseHover className={`${linkStyle.join(" ")}`}>{children.toString()}</MouseHover></a> : null}
            {type === LinkTypes.LINK ?  <Link to={url} className={"text-decoration-none"}><MouseHover className={`${linkStyle.join(" ")}`}>{children.toString()}</MouseHover></Link> : null}
            {type === LinkTypes.REDIRECT ?  <a href={url} className={"text-decoration-none"} target={"_blank"}><MouseHover className={`${linkStyle.join(" ")}`}>{children.toString()}</MouseHover></a> : null}
        </>
    )
}