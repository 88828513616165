import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {LinkTypes} from "../UI/Link";
import {useTranslation} from "react-i18next";
import {OptionContext} from "../../context/option-context";
import {NavigationLink} from "../UI/NavigationLink";
import Flags from 'country-flag-icons/react/3x2'
import {BiWorld} from "react-icons/bi"

const flagStyle = {
    width: "35px",
    height: "35px"
}

export const Header = (props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.hash);
    const [activeLink, setActiveLink] = useState("");
    const [navBg, setNavBg] = useState(false)
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (params.entries().next().value) {
            const value = params.entries().next().value[0];
            setActiveLink(value)
        }
    }, [params])

    const onLanguageChange = (value) => {
        localStorage.setItem("locale", value);
        i18n.changeLanguage(value)
    }

    document.body.onscroll = () => {
        setNavBg(window.scrollY > 50)
    }

    return <header className={`sticky-top`}>
        <nav className={`navbar navbar-expand-lg navbar-light bg-white`}>
            <div className="container-fluid mx-3">
                <div className="navbar-brand">
                    <img width={50}
                         className={"bg-primary rounded-circle border-0"}
                         src="images/logo.png"
                         alt={"Accessibility-MK Logo"}/>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id={"navbarText"}>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                        <li className="nav-item">
                            <NavigationLink
                                className={`nav-link  ${activeLink === "#accessibility" || activeLink === "" ? "nav-link-active" : ""}`}
                                type={LinkTypes.HASH} url="#home">accessibility</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink
                                className={`nav-link ${activeLink === "#disability" ? "nav-link-active" : ""}`}
                                type={LinkTypes.HASH} url="#disability">disability</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink className={`nav-link ${activeLink === "#sectors" ? "nav-link-active" : ""}`}
                                            type={LinkTypes.HASH} url="#sectors">sectors</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink
                                className={`nav-link ${activeLink === "#information-access" ? "nav-link-active" : ""}`}
                                type={LinkTypes.HASH} aria-current="page"
                                url="#information-access">information-access</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink
                                className={`nav-link ${activeLink === "#platforms" ? "nav-link-active" : ""}`}
                                type={LinkTypes.HASH} url="#platforms">platforms</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink
                                className={`nav-link ${activeLink === "#partners" ? "nav-link-active" : ""}`}
                                type={LinkTypes.HASH} url="#partners">partners</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink
                                className={`nav-link ${activeLink === "#about-us" ? "nav-link-active" : ""}`}
                                type={LinkTypes.HASH} url="#about-us">about-us.h1</NavigationLink>
                        </li>
                        <li className="nav-item">
                            <NavigationLink className={`nav-link ${activeLink === "#contact" ? "nav-link-active" : ""}`}
                                            type={LinkTypes.HASH} url="#contact">contact</NavigationLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto mb-lg-0">

                        <li className={"mx-auto mb-1 my-0 border-end px-1 my-0"}>
                            <button
                                type={"button"}
                                style={flagStyle}
                                className={`btn btn-link text-dark text-center text-decoration-none p-0 nav-link-active`}
                                onClick={() => onLanguageChange("mk")}>
                                {/*<Flags.MK />*/}
                                MK
                            </button>
                        </li>
                        <li className={"mx-auto mb-1 my-0 border-end px-1 my-0"}>
                            <button
                                type={"button"}
                                style={flagStyle}
                                className={`btn btn-link text-dark text-center text-decoration-none p-0 nav-link-active`}
                                onClick={() => onLanguageChange("al")}>
                                {/*<Flags.AL />*/}
                                AL
                            </button>
                        </li>
                        <li className={"mx-auto mb-1 my-0 px-1 my-0"}>
                            <button
                                type={"button"}
                                style={flagStyle}
                                className={`btn btn-lin text-dark text-center text-decoration-none p-0 nav-link-active`}
                                onClick={() => onLanguageChange("en")}>
                                {/*<Flags.US />*/}
                                EN
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
}