import React, {useEffect, useState} from 'react';
import axios from "axios";

export const AudioContext = React.createContext({
    text: null,
    audio: null,
    isPlaying: false,
    loading: false,
    onChangeAudio: (value) => {
    },
    onPause: () => {
    },
    onPlay: () => {
    },
    onClose: () => {
    }
})

export const AudioContextProvider = props => {
    const [text, setText] = useState(null);
    const [audio, setAudio] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isPlaying) {
            const locale = localStorage.getItem("locale");
            setLoading(true);

            if (locale === "mk") {
https://tts-all.ireason.mk/generate?lang=mk-MK&token=eq19u1bgr&text=podczas%20spotkania%20odb%C4%99dzie%20si%C4%99%20r%C3%B3wnie%C5%BC%20promocja%20wydawnictwa%20multimedialnego%20sobiescy%20zachowane%20w%20d%C5%BAwi%C4%99kach%20,%20pod.%20redakcj%C4%85%20anety%20i.%20oborny&gender=f
                setAudio(new Audio(`https://tts-all.ireason.mk/generate?lang=mk-MK&token=eq19u1bgr&text=${text}&gender=f`));
            } else if (locale === "en") {
		setAudio(new Audio(`https://tts-all.ireason.mk/generate?lang=en-US&token=eq19u1bgr&text=${text}&gender=f`));
            } else if (locale === "al") {
                setAudio(new Audio(`https://tts-sq.ireason.mk/generate?text=${text}`));
            }
        } else {
            if (audio) {
                audio.pause();
            }
            setAudio(null);
        }
    }, [isPlaying])

    useEffect(() => {
        if (audio && isPlaying) {
            audio.play();
            audio.oncanplay = () => setLoading(false)
            audio.onended = () => {
                audio.currentTime = 0;
                setIsPlaying(false)
            }
        }
    }, [audio])

    const onTextChange = (value) => {
        if (value) {
            setText(value);
            // setAudio(new Audio(`https://tts.ireason.mk/predict?text=${value}&locale=${locale ? locale : "mk"}`));

            setIsPlaying(true)
        } else {
            onCloseHandle();
        }
    }

    const onPlay = () => {
        setIsPlaying(true);
    }

    const onPause = () => {
        setIsPlaying(false);
    }

    const onCloseHandle = () => {
        setText(null);
        setAudio(null);
        setIsPlaying(false);
    }

    return (
        <AudioContext.Provider value={{
            text: text,
            audio: audio,
            isPlaying: isPlaying,
            loading: loading,
            onChangeAudio: onTextChange,
            onPause: onPause,
            onPlay: onPlay,
            onClose: onCloseHandle
        }}>
            {props.children}
        </AudioContext.Provider>
    )
}
