import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import classes from "./options.module.css"
import {MouseHover} from "./MouseHover";
import {getParagraphCssClass} from "./Paragraph";
import {useTranslation} from "react-i18next";

export const Button = ({children, onClick, className, type}) => {
    const optionsContext = useContext(OptionContext);

    const onClickHandle = () => {
        if (onClick) {
            onClick();
        }
    }

    const buttonClasses = [];

    buttonClasses.push(getParagraphCssClass(optionsContext.fontSize, className))

    if (optionsContext.highlightText) {
        buttonClasses.push(classes.Highlight)
    }

    return (
        <button type={type} className={className} onClick={onClickHandle}>
            <MouseHover className={buttonClasses.join(" ")}>{children.toString()}</MouseHover>
        </button>
    )
}