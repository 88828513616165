import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import classes from "./options.module.css"
import {MouseHover} from "./MouseHover";
import {getParagraphCssClass} from "./Paragraph";

export const TextCopyright = ({text, linkText, url, className}) => {
    const optionsContext = useContext(OptionContext);

    const paragraphStyle = [className];

    paragraphStyle.push(getParagraphCssClass(optionsContext.fontSize, className))

    if (optionsContext.highlightText) {
        paragraphStyle.push(classes.Highlight)
    }


    return (
        <p className={paragraphStyle.join(" ")}>
            <MouseHover>{text}</MouseHover>&nbsp;
            <a href={url} target={"_blank"} className={optionsContext.linkMark ? classes.Link : ""}><MouseHover >{linkText}</MouseHover></a>
        </p>
    )
}