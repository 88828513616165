import React from "react";
import {Heading} from "../UI/Heading";
import {useTranslation} from "react-i18next";

export const Partners = (props) => {
    const {t} = useTranslation();

    return (
        <section id={"partners"} className={"container my-3 mt-5 mx-auto"}>
            <Heading type={1} className={"text-center"}>partners</Heading>
            <div className={"row mt-3 text-center"}>
                <div className={"col-sm-12 col-md-6 col-lg-3 my-2"}>
                    <div className={"m-2 p-2 rounded-3 shadow frame owl-carousel-item d-flex h-100 partners-item"}>
                        <img
                            width={"100%"}
                            className={"partners-img my-auto justify-content-center align-self-center"}
                            src={t("FINKI-IMG")}
                            alt={"Partners FINKI"}/>
                    </div>
                </div>
                <div className={"col-sm-12 col-md-6 col-lg-3 my-2"}>
                    <div className={"m-2 p-2 rounded-3 shadow frame owl-carousel-item d-flex h-100 partners-item"}>
                        <img
                            width={"100%"}
                            className={"partners-img my-auto justify-content-center align-self-center"}
                            src={t("FZF-IMG")}
                            alt={"Partner FZF"}/>
                    </div>
                </div>
                <div className={"col-sm-12 col-md-6 col-lg-3 my-2"}>
                    <div className={"m-2 p-2 rounded-3 shadow frame owl-carousel-item d-flex h-100 partners-item"}>
                        <img
                            width={"100%"}
                            className={"partners-img my-auto justify-content-center p-4 align-self-center"}
                            src={t("IREASON-IMG")}
                            alt={"Partner FZF"}/>
                    </div>
                </div>
                <div className={"col-sm-12 col-md-6 col-lg-3 my-2"}>
                    <div className={"m-2 p-2 rounded-3 shadow frame owl-carousel-item d-flex h-100 partners-item"}>
                        <img
                            width={"100%"}
                            className={"partners-img my-auto justify-content-center align-self-center p-5"}
                            src={"images/st-logo.png"}
                            alt={"Partners FINKI"}/>
                    </div>
                </div>
            </div>
        </section>
    )
}