import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import classes from "./options.module.css";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import {MouseHover} from "./MouseHover";
import {useTranslation} from "react-i18next";

export const Heading = ({children, className, type}) => {
    const optionsContext = useContext(OptionContext);

    const headingStyle = [];

    let heading = null

    if (optionsContext.highlightText) {
        headingStyle.push(classes.Highlight)
    }

    let fontSize = optionsContext.fontSize > 1 ? optionsContext.fontSize * 0.3 : 0;

    if (type === 1) {
        fontSize += 2;
        heading = <h1 style={{fontSize: `${fontSize}em`}} className={className}><MouseHover className={headingStyle.join(" ")}>{children.toString()}</MouseHover></h1>
    } else if (type === 2) {
        fontSize += 1.5;
        heading = <h2 style={{fontSize: `${fontSize}em`}} className={className}><MouseHover className={headingStyle.join(" ")}>{children.toString()}</MouseHover></h2>
    } else if (type === 3) {
        fontSize += 1.17;
        heading = <h3 style={{fontSize: `${fontSize}em`}} className={className}><MouseHover className={headingStyle.join(" ")}>{children.toString()}</MouseHover></h3>
    } else if (type === 4) {
        fontSize += 1;
        heading = <h4 style={{fontSize: `${fontSize}em`}} className={className}><MouseHover className={headingStyle.join(" ")}>{children.toString()}</MouseHover></h4>
    } else if (type === 5) {
        fontSize += 0.83
        heading = <h5 style={{fontSize: `${fontSize}em`}} className={className}><MouseHover className={headingStyle.join(" ")}>{children.toString()}</MouseHover></h5>
    } else if (type === 6) {
        fontSize += 0.67
        heading = <h6 style={{fontSize: `${fontSize}em`}} className={className}><MouseHover className={headingStyle.join(" ")}>{children.toString()}</MouseHover></h6>
    }


    return (
        heading
    )
}