import React, {useContext, useEffect, useRef} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import {FaPlay, FaPause} from "react-icons/fa";

export const Video = props => {
    const optionsContext = useContext(OptionContext);
    const audioContext = useContext(AudioContext);
    const videoContext = useContext(VideoContext);

    let ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            videoContext.onHide();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <div ref={ref} style={{position: "fixed", bottom: "30px", left: "20px", width: "auto", zIndex: 1}}>
            {
                optionsContext.sound && audioContext.text ?
                    <div className={"mb-2"}>
                        {!audioContext.isPlaying || (audioContext.audio && audioContext.audio.ended) ?
                            <button className={"btn btn-light bg-green rounded-circle border"}
                                    onClick={audioContext.onPlay}><FaPlay/></button> :
                            <button className={"btn btn-light bg-green rounded-circle border"}
                                    onClick={audioContext.onPause}><FaPause/></button>}
                    </div> : null
            }
            {
                optionsContext.signMark && videoContext.url ?
                    <div style={{maxWidth: "350"}} className={"my-1"}>
                        <video className="dms-video"
                               controls
                               autoPlay={true}
                               id="video"
                               width="350">
                            <source
                                src={videoContext.url}
                                type="video/mp4"/>
                        </video>
                    </div> : null
            }
        </div>
    )
}