import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "@popperjs/core/dist/cjs/popper"
import "bootstrap/dist/css/bootstrap.css"
import 'bootstrap/dist/js/bootstrap.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {OptionContextProvider} from "./context/option-context";
import {AudioContextProvider} from "./context/audio-context";
import {VideoContextProvider} from "./context/video-context";
import './i18next';

ReactDOM.render(
    <OptionContextProvider>
        <AudioContextProvider>
            <VideoContextProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </VideoContextProvider>
        </AudioContextProvider>
    </OptionContextProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
