import React from "react";
import {Paragraph} from "../UI/Paragraph";
import {Heading} from "../UI/Heading";
import {Link, LinkTypes} from "../UI/Link";
import {TextCopyright} from "../UI/TextCopyright";

const DisabilityData = [
    {
        title: "disability-persons",
        text: "disability-persons.p1",
        className: "col-12 my-2"
    },
    {
        title: "visual",
        text: "visual.p1",
        className: "col-sm-12 col-md-4 my-2"
    },
    {
        title: "audiological",
        text: "audiological.p1",
        className: "col-sm-12 col-md-4 my-2"
    },
    {
        title: "attacks",
        text: "attacks.p1",
        className: "col-sm-12 col-md-4 my-2"
    },
    {
        title: "motor-mobility",
        text: "motor-mobility.p1",
        className: "col-sm-12 col-md-6 my-2"
    },
    {
        title: "cognitive-intellectual",
        text: "cognitive-intellectual.p1",
        className: "col-sm-12 col-md-6 my-2"
    },
]

export const Disability = props => {

    const showModal = (disability) => {
        props.showModal(
            disability.title,
            <Paragraph className={"bg-white"}>
                {disability.text}
            </Paragraph>
        )
    }

    return (
        <section id={"disability"} className={"container my-3 clearfix"}>
                <Heading type={1} className={"text-center mx-2"}>disability.h1</Heading>
                <div className={"row"}>
                    {
                        DisabilityData && DisabilityData.map(disability => {

                            return <div key={disability.title} className={disability.className}>
                                <div className={"card h-100 accessibility-item d-none d-md-flex"}>
                                    <div className={"card-body text-center"}>
                                        <Heading type={4}>{disability.title}</Heading>
                                        <Paragraph>
                                            {disability.text}
                                        </Paragraph>
                                    </div>
                                </div>
                                <div className={"card h-100 mx-2 accessibility-item mx-auto d-md-none d-sm-flex"}
                                     onClick={() => showModal(disability)}>
                                    <div className={"card-body text-center"}>
                                        <Heading type={4}>{disability.title}</Heading>
                                        <Paragraph className={"small"}>click-show-more</Paragraph>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                <TextCopyright
                    className={"small mx-3 p-1"}
                    text={"copy-right.p1"}
                    linkText={"link"}
                    url={"http://inkluziva.mk/wp-content/uploads/2018/12/Izvestaj-za-pristapnost-na-web-strani-final.pdf"} />
            </div>
        </section>

    )
}