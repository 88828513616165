import './App.css';
import {Header} from "./Navbar/Header";
import React, {useContext, useEffect, useState, Suspense} from "react";
import classes from "./UI/options.module.css";
import 'react-phone-input-2/lib/bootstrap.css'

import {Sectors} from "./Sectors/Sectors";
import {Platforms} from "./Platforms/Platforms";
import {Partners} from "./Partners/Partners";
import {MainBanner} from "./Banners/MainBanner";
import {Disability} from "./Disability/Disability";
import {InformationAccess} from "./InformationAccess/InformationAccess";
import {Options} from "./Options/Options";
import {OptionContext} from "../context/option-context";
import {AudioContext} from "../context/audio-context";
import {Video} from "./Video/Video";
import {Heading} from "./UI/Heading";
import {Contact} from "./Contact/Contact";
import {AboutUs} from "./AboutUs/AboutUs";
import {useTranslation} from "react-i18next";
import {GiSoundWaves} from "react-icons/gi";
import {Paragraph} from "./UI/Paragraph";
import {ModalUI} from "./UI/Modal";
import {MouseHover} from "./UI/MouseHover";

function App() {
    const optionsContext = useContext(OptionContext);
    const audioContext = useContext(AudioContext);
    const {t, i18n} = useTranslation();

    const [showModal, setShowModal] = useState({
        open: false,
        title: '',
        children: <></>
    })

    useEffect(() => {
        if (localStorage.getItem("locale")) {
            i18n.changeLanguage(localStorage.getItem("locale"));
        } else {
            localStorage.setItem('locale', 'mk');
        }
        let options = localStorage.getItem("options");
        if (options) {
            options = JSON.parse(options);
            if (options.sound) {
                onOpenModal("", <div className={"text-center item-text"}>
                    <GiSoundWaves size={100} className="m-2"/>
                    <Heading type={3}>warning</Heading>
                    <Paragraph>warning.p1</Paragraph>
                    <div className={"btn-group my-2"}>
                        <button className={"btn btn-danger"} onClick={onCancelAudio}>
                            <MouseHover>{t("cancel")}</MouseHover></button>
                        <button className={"btn btn-success"} onClick={onCloseModal}>
                            <MouseHover>{t("accept")}</MouseHover></button>
                    </div>
                </div>)
            }
        }

    }, [])


    const onOpenModal = (title, children) => {
        setShowModal({
            open: true,
            title: title,
            children: children
        })
    }

    const onCancelAudio = () => {
        optionsContext.onSoundChange();
        onCloseModal();
    }

    const onCloseModal = () => setShowModal({open: false, title: "", children: <></>})

    const bodyClasses = [];
    if (optionsContext.mousePointer) {
        bodyClasses.push(classes.IncrementMouse)
    }

    if (optionsContext.textSpacing) {
        bodyClasses.push(classes.TextSpacing)
    }
    if (optionsContext.dyslexia) {
        bodyClasses.push(classes.DyslexiaFont);
    }

    if (audioContext.loading) {
        bodyClasses.push(classes.Loading);
    }

    return (
        <Suspense fallback={<></>}>
            <div id={"home"} className={bodyClasses.join(" ")}>
                <Header/>
                {/*<Accessibility/>*/}
                <MainBanner showModal={onOpenModal}/>
                <InformationAccess showModal={onOpenModal}/>
                <Disability showModal={onOpenModal}/>
                <Sectors showModal={onOpenModal}/>

                <Platforms showModal={onOpenModal}/>
                <AboutUs showModal={onOpenModal}/>
                <Partners showModal={onOpenModal}/>
                <Contact showModal={onOpenModal}/>
                <Options/>
                <Video/>
                <ModalUI isOpen={showModal.open}
                         title={showModal.title}
                         body={showModal.children}
                         onClose={onCloseModal}/>
            </div>
        </Suspense>

    );
}

export default App;
