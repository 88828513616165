import React, {useContext, useState} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import {useTranslation} from "react-i18next";

export const MouseHover = ({children, className, signKey}) => {
    const optionsContext = useContext(OptionContext);
    const audioContext = useContext(AudioContext);
    const videoContext = useContext(VideoContext);
    const {t} = useTranslation();
    const [delayHandler, setDelayHandler] = useState(null)

    const onClickHandle = (text) => {
        if (optionsContext.sound) {
            text = t(text);
            audioContext.onChangeAudio(text)
        }
        if (optionsContext.signMark) {
            videoContext.onChangeVideo(text)
        }
    }

    const handleMouseEnter = (text) => {
        setDelayHandler(setTimeout(() => {
            if (optionsContext.sound) {
                text = t(text);
                if (text)
                    audioContext.onChangeAudio(text)
            }
            if (optionsContext.signMark) {
                if (text)
                    videoContext.onChangeVideo(text)
            }
        }, 500))
    }



    const handleMouseLeave = () => {
        clearTimeout(delayHandler)
    }

    const paragraphStyle = [className];

    return (
        <span className={paragraphStyle.join(" ")} onMouseEnter={()=>handleMouseEnter(children)} onMouseLeave={()=>handleMouseLeave(children)} onClick={() => onClickHandle(children)}>
            {
                t(children)
            }
        </span>
    )
}