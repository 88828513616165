import React from "react";
import ReactOwlCarousel from "react-owl-carousel";
import {BiCheck} from "react-icons/bi";
import {Paragraph} from "../UI/Paragraph";
import {Heading} from "../UI/Heading";
import {Button} from "../UI/Button";
import {MouseHover} from "../UI/MouseHover";
import './MainBanner.module.css'
import './MainBanner.css'

export const MainBanner = (props) => {

    const showMoreAccessibility = () => {
        props.showModal(
            "Плагини кој ги овозможува Accessibility",
            <div className={"row overflow-auto"} style={{maxHeight: "450px"}}>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                tts
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                dyslexic-font
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                dyslexic-color-set
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                sign-language.p1
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                increase-cursor.p1
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                highlight-links.p1
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                increase-font.p1
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-12 mx-0 my-2"}>
                    <div className={"card h-100 m-2 accessibility-item"}>
                        <div className={"card-body text-center"}>
                            <BiCheck size={50} className={"text-success"} color={"success"}/>
                            <Paragraph>
                                text-spacing.p1
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <section id={"main-banner"} className={"background-full p-5"}>
            <ReactOwlCarousel items={1}
                              id={"accessibility"}
                              className="owl-one owl-theme"
                              loop
                              dots={true}
                              autoplay={true}
                              autoplayTimeout={5000}
                              autoplaySpeed={1800}
                              autoplayHoverPause={true}
                              margin={5}>
                <div id={"accessibility-mb"} className={"container mb-5"}>
                    <div className="p-0">
                        <div className={"row mx-2 "}>
                            <Heading type={1} className={`display-5 fw-bold px-0`}>accessibility.h1</Heading>
                            <Paragraph className={"card mt-2 mb-2 p-1"} style={{"fontSize":"16px"}}>accessibility.info</Paragraph>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            tts
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            dyslexic-font
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            dyslexic-color-set
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            sign-language.p1
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            increase-cursor.p2
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            highlight-links.p1
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            increase-font.p1
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-4 col-lg-3 main-banner-item my-2"}>
                                <div className={"card h-100 m-2 accessibility-item"}>
                                    <div className={"card-body text-center"}>
                                        <BiCheck size={50} className={"text-success"} color={"success"}/>
                                        <Paragraph>
                                            text-spacing.p1
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12 w-100 text-center my-2 d-lg-none main-banner-item"}>
                                <button className={"btn btn-primary text-light rounded-pill"}
                                        onClick={showMoreAccessibility}>
                                    <MouseHover>show-more</MouseHover>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </ReactOwlCarousel>
        </section>

    )
}