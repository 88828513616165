import React, {useContext} from "react";
import {RiFontSize} from "react-icons/ri"
import {FaAmericanSignLanguageInterpreting, FaMousePointer} from "react-icons/fa"
import {GiSoundWaves} from 'react-icons/gi'
import {LinearProgress} from "@material-ui/core";
import {FiLink} from "react-icons/fi";
import {AiOutlineFontColors} from "react-icons/ai";
import {OptionContext} from "../../context/option-context";
import {getParagraphCssClass, Paragraph} from "../UI/Paragraph";
import {useTranslation} from "react-i18next";
import {CgArrowsH} from "react-icons/cg";
import {ImFont} from "react-icons/im";
import {Button} from "../UI/Button";

export const OptionsMenu = props => {
    const optionsContext = useContext(OptionContext);
    const maxWidth = 330 + (30 * optionsContext.fontSize);

    return (
        <>
            <div style={{maxWidth: `${maxWidth}px`, maxHeight: "calc(100vh - 200px)", zIndex: 0}} className={"my-3"} >
                <div className={"border bg-white ms-auto card"}  style={{maxHeight: "calc(100vh -  200px)"}}>
                    <div className={"card-header bg-white options-card-header text-center"}>
                        <Paragraph className={"text-center text-white pb-0 mb-0"} type={6}>menu-items</Paragraph>
                    </div>
                    <div className={"card-body overflow-auto"}  style={{maxHeight: "calc(100vh -  200px)"}}>
                        <div className={"row"}>
                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.onDyslexiaChange()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.dyslexia ? "bg-primary bg-opacity-25" : ""}`}>
                                    <ImFont size={40} className="mt-3"/>
                                    <div className={"text-center item-text"}>
                                        <Paragraph className={"text-center"}>dyslexia</Paragraph>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.onTextSpacingChange()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.textSpacing ? "bg-primary bg-opacity-25" : ""}`}>
                                    <CgArrowsH size={50} className="mt-1"/>
                                    <div className={"text-center item-text"}>
                                        <Paragraph className={"text-center"}>text-spacing.p2</Paragraph>
                                    </div>

                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.onSignMarkChange()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.signMark ? "bg-primary bg-opacity-25" : ""}`}>
                                    <FaAmericanSignLanguageInterpreting size={50} className="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <Paragraph>sign-language.p2</Paragraph>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.onSoundChange()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.sound ? "bg-primary bg-opacity-25" : ""}`}>
                                    <GiSoundWaves size={50} className="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <Paragraph>audio-assistant</Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6 col-sm-12 p-1 pb-0"}
                                 onClick={() => optionsContext.onHighlightTextChange()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.highlightText ? "bg-primary bg-opacity-25" : ""}`}>
                                    <AiOutlineFontColors size={50} className="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <Paragraph>highlight-text</Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.onLinkMarkChange()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.linkMark ? "bg-primary bg-opacity-25" : ""}`}>

                                    <FiLink size={50} className="m-2"/>
                                    <div className={"text-center item-text"}>
                                        <Paragraph>highlight-links.p2</Paragraph>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.incrementFontSize()}>
                                <div className={"h-100 border text-center"}>
                                    <RiFontSize size={50} className="m-2"/>
                                    <div className={"text-center item-text pb-0"}>
                                        <Paragraph>increase-font.p2</Paragraph>
                                        <LinearProgress variant="determinate" value={optionsContext.fontSize * 25}/>
                                    </div>

                                </div>
                            </div>
                            <div className={"col-md-6 col-sm-12 p-1"}
                                 onClick={() => optionsContext.incrementMousePointer()}>
                                <div
                                    className={`h-100 border text-center ${optionsContext.mousePointer ? "bg-primary bg-opacity-25" : ""}`}>
                                    <FaMousePointer size={50} className="m-2"/>
                                    <div className={"text-center item-text pb-0"}>
                                        <Paragraph>increase-cursor.p2</Paragraph>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12 h-100 p-1"}>
                                <Button className={"btn btn-primary w-100"} onClick={() => optionsContext.reset()}>reset</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}