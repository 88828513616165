import React, {useEffect, useState} from "react";
import {OptionsMenu} from "./OptionsMenu";
import {FaUniversalAccess} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import "./options.css"

export const Options = props => {
    const [openOptions, setOpenOptions] = useState(false);
    const [showMessage, setShowMessage] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        setTimeout(() =>{
            setShowMessage(false)
        }, 3000)
    }, [])

    return (
        <div style={{position: "fixed", bottom: "30px", right: "20px", width: "auto", zIndex: 1}}>
            {
                openOptions && <OptionsMenu isOpen={openOptions} onClose={() => setOpenOptions(false)} />
            }

            <div className={"popup float-end"}>
                <FaUniversalAccess
                    className={"float-end"}
                    onClick={() => setOpenOptions(!openOptions)}
                    title={t("set-options")}
                    id={"options-button"} />
                {
                    <span className={`popuptext ${showMessage ? "show" : "hide"}`} id="myPopup">{t("click-me")}</span>
                }

                {/*<img width={50}*/}
                {/*     onClick={() => setOpenOptions(!openOptions)}*/}
                {/*     className={"float-end rounded-circle"}*/}
                {/*     title={"Постави поставки"}*/}
                {/*     id={"options-button"}*/}
                {/*     src="accessibility.png"*/}
                {/*     alt={"Accessibility-MK Logo"}/>*/}
            </div>
        </div>
    )
}