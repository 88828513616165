import React from "react";
import {Heading} from "../UI/Heading";
import {Paragraph} from "../UI/Paragraph";
import {useTranslation} from "react-i18next";

export const AboutUs = props => {
    const {t} = useTranslation();

    return (
        <section id={"about-us"} className={"container my-5"}>
            <div className={"row mx-auto my-5 py-5 text-start"}>
                <div className={"col-sm-12 mx-auto bg-white rounded border shadow p-4"}>
                    <Heading type={1} className={"text-center"}>about-us.h1</Heading>
                    <div className={"row"}>

                        <div className={"col-sm-12 col-md-8 my-auto p-0 mx-0"}>
                            <div className={"card my-auto ms-2 bg-white border-0 rounded-0 "}>
                                <div className={"card-body m-2"}>
                                    <Paragraph>
                                        about-us.p1
                                    </Paragraph>
                                </div>
                            </div>

                        </div>
                        <div className={"col-sm-12 col-md-4 p-3 bg-white m-0 img-fluid"}>
                            <img className={"img-fluid"}
                                alt={"iReason logo"}
                                src={t("IREASON-IMG")}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}