import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import classes from "./options.module.css";
import {useTranslation} from "react-i18next";
import {MouseHover} from "./MouseHover";

export const LinkTypes = {
    "HASH": "HASH",
    "LINK": "LINK",
    "REDIRECT": "REDIRECT"
}

export const NavigationLink = ({children, className, url, type}) => {
    const optionsContext = useContext(OptionContext);
    const {t} = useTranslation();
    const linkStyle = [className];

    if (optionsContext.linkMark) {
        linkStyle.push(classes.Link)
    }
    else if (optionsContext.highlightText && !optionsContext.linkMark) {
        linkStyle.push(classes.Highlight)
    }

    return (
        <>
            <a href={url} className={"text-decoration-none"}><MouseHover className={`${linkStyle.join(" ")}`}>{children.toString()}</MouseHover></a>
        </>
    )
}