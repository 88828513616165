import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const ReCaptcha = ({onChange}) => {
    return (
        <ReCAPTCHA
            sitekey="6LdcPyoeAAAAAAVxGjp32Qm66UaqjrR2HJGFoW7f"
            onChange={onChange}
        />
    )
}