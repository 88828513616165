import React, {useContext} from "react";
import {OptionContext} from "../../context/option-context";
import {AudioContext} from "../../context/audio-context";
import {VideoContext} from "../../context/video-context";
import classes from "./options.module.css"
import {MouseHover} from "./MouseHover";
import {getParagraphCssClass} from "./Paragraph";

export const Label = ({children, htmlFor, className}) => {
    const optionsContext = useContext(OptionContext);

    const labelClasses = [className];


    labelClasses.push(getParagraphCssClass(optionsContext.fontSize, className))

    if (optionsContext.highlightText) {
        labelClasses.push(classes.Highlight)
    }

    return (
        <label htmlFor={htmlFor} className={className}>
            <MouseHover className={`${labelClasses.join(" ")}`}>{children.toString()}</MouseHover>
        </label>
    )
}