import React from "react";
import {Paragraph} from "../UI/Paragraph";
import {Heading} from "../UI/Heading";
import {Link, LinkTypes} from "../UI/Link";
import {TextCopyright} from "../UI/TextCopyright";

export const InformationAccess = props => {
    return (
        <section id={"information-access"} className={"container my-5 clearfix"}>
            <Heading type={1} className={"text-center my-5"}>
                information-access.h1
            </Heading>
            <div className={"row d-none d-md-flex"}>
                <div className={"col-sm-12 col-md-8"}>
                    <div className={"card my-auto overflow-auto bg-white card-overflow border-0 rounded-0 shadow"}
                         style={{maxHeight: "20vw"}}>
                        <div className={"card-body m-2"}>
                            <Paragraph>
                                information-access.p1
                            </Paragraph>
                            <Paragraph>
                                information-access.p2
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className={"col-md-1"}></div>
                <div className={"col-3 mx-0 p-0"}>
                    <img
                        width={"100%"}
                        className={"img-fluid"}
                        alt={"Information access"}
                        src={"/images/logo.png"}/>
                </div>
            </div>
            <div className={"row d-sm-flex d-md-none"}>
                <div className={"col-sm-12 my-auto p-0 mx-0"}>
                    <div className={"card my-auto m-2 overflow-auto bg-white card-overflow border-0 rounded-0 shadow"}
                         style={{maxHeight: "70vw"}}>
                        <div className={"card-body m-1"}>
                            <Paragraph>
                                information-access.p1
                            </Paragraph>
                            <Paragraph>
                                information-access.p2
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}