import React from "react";
import {RiGovernmentFill, RiBankFill} from "react-icons/ri";
import {HiAcademicCap} from "react-icons/hi";
import {GiReceiveMoney, GiInjustice, GiMoneyStack} from "react-icons/gi";
import {MdSecurity} from "react-icons/md";
import {BsTelephoneFill} from "react-icons/bs";
import {GrTechnology} from "react-icons/gr";
import {BiHealth} from "react-icons/bi";
import {FaTruck, FaHands} from "react-icons/fa";
import {GrMultimedia} from "react-icons/gr";
import ReactOwlCarousel from "react-owl-carousel";
import {Heading} from "../UI/Heading";

const SectorData = [
    {
        name: "government-public-sector",
        icon: <RiGovernmentFill size={100}/>,
    },
    {
        name: "education-sector",
        icon: <HiAcademicCap size={100}/>
    },
    {
        name: "banking-sector",
        icon: <RiBankFill size={100}/>
    },
    {
        name: "financial-sector",
        icon: <GiReceiveMoney size={100}/>
    },
    {
        name: "insurance-sector",
        icon: <MdSecurity size={100}/>
    },
    {
        name: "telecommunication-sector",
        icon: <BsTelephoneFill size={100}/>
    },
    {
        name: "it-sector",
        icon: <GrTechnology size={100}/>
    },
    {
        name: "health-sector",
        icon: <BiHealth size={100}/>
    },
    {
        name: "legal-sector",
        icon: <GiInjustice size={100}/>
    },
    {
        name: "sales-sector",
        icon: <GiMoneyStack size={100}/>
    },
    {
        name: "transport-sector",
        icon: <FaTruck size={100}/>
    },
    {
        name: "non-governmental-organizations",
        icon: <FaHands size={100}/>
    },
    {
        name: "multimedia-sector",
        icon: <GrMultimedia size={100}/>
    },
]

export const Sectors = (props) => {

    return <section id={"sectors"} className={"container my-3 mt-5"}>
        <Heading type={1} className={"text-center mx-2"}>support-sectors</Heading>
        <ReactOwlCarousel items={5}
                          className="owl-theme"
                          loop
                          dots={false}
                          autoplay={true}
                          autoplayTimeout={2000}
                          autoplaySpeed={1800}
                          autoplayHoverPause={true}
                          responsive={
                              {
                                  0:{
                                      items:1
                                  },
                                  600: {
                                      items: 2
                                  },
                                  750:{
                                      items:3
                                  },
                                  1000:{
                                      items:5
                                  }
                              }
                          }
                          margin={5}>
            {
                SectorData && SectorData.map(sector => {
                    return <div key={sector.name} className={"rounded-3 border shadow m-3 my-5 owl-rtl owl-carousel-item"}>
                        <div className={`d-flex`}>
                            <div className={"mx-auto pt-3"}>
                                {sector.icon}
                            </div>
                        </div>
                        <Heading type={5} className={"text-center py-2"}>{sector.name}</Heading>
                    </div>
                })
            }
        </ReactOwlCarousel>
    </section>
}